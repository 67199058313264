import { Controller } from "@hotwired/stimulus";
import AutoNumeric from "autonumeric";

import { CurrencySymbolPlacementOption, Options } from "autonumeric";

type SupportedNumberFormats = "number" | "currency" | "area";

/**
 * Format numeric <input> according to locale.
 *
 * When working with numbers like currencies, the <input type="number"> only
 * offers limited functionality to localize the value. Foremost, it lacks a
 * thousands separator (e.g., 1.000.000 in german locale) which is very
 * inconvenient to work with.
 *
 * This controller wraps the autonumeric.js plugin to provide that
 * functionality. We chose to wrap it in a controller so we can easily swap the
 * plugin any time and have all initialization done here.
 */
export default class extends Controller<HTMLInputElement> {
    static values = { format: { type: String, default: "number" }, locale: String };

    declare formatValue: SupportedNumberFormats;
    declare localeValue: string;

    connect() {
        let config = {};

        const baseConfig: Options = {
            digitGroupSeparator: ".",
            decimalCharacter: ",",
            // Submit unformatted numbers: This allows us to treat any
            // autonumeric-formatted input as regular WTForm number fileds
            // instead of using a text field.
            unformatOnSubmit: true,
        };

        switch (this.formatValue) {
            case "number":
                config = { ...baseConfig };
                break;

            case "currency":
                config = {
                    ...baseConfig,
                    currencySymbol: "\u202f€",
                    currencySymbolPlacement: "s" as CurrencySymbolPlacementOption,
                };
                break;

            case "area":
                config = { ...baseConfig, suffixText: "\u202fm²" };
                break;

            default:
                break;
        }

        new AutoNumeric(this.element, null, config);
    }
}
